import { t } from 'react-switch-lang';
import React, { Fragment } from 'react';
import { events } from '../../utils/Amplitude';
import { ExternalLink } from '../Link';
import style from '../../styles/AuthForm.module.scss';

function DisclaimerReplaceText({
  text,
  replaceKeyFirst,
  replaceKeySecond,
  replaceWithFirst,
  replaceWithSecond,
  exceptions,
}) {
  if (!text.includes(replaceKeyFirst)) return text;
  if (!text.includes(replaceKeySecond)) return text;

  return text.replace(replaceKeyFirst, 'xReplace').replace(replaceKeySecond, 'xReplace').split('xReplace').map((e, i) => {
    if (i === 0) return e;
    if (i === 2) {
      return (
        <Fragment key={e}>
          {exceptions?.some((exception) => e.startsWith(exception)) ?
            replaceWithSecond :
            replaceWithSecond}
          {e}
        </Fragment>
      );
    }
    return (
      <Fragment key={e}>
        {exceptions?.some((exception) => e.startsWith(exception)) ?
          replaceKeyFirst :
          replaceWithFirst}
        {e}
      </Fragment>
    );
  });
}

export default function GoogleRecaptchaDisclaimer() {
  return (
    <div className={style.googleRecaptchaDisclaimer}>
      <p>
        <DisclaimerReplaceText
          text={t('Recaptcha_Disclaimer_Lbl')}
          replaceWithFirst={(
            <ExternalLink
              href={t('Link_Google_Privacy')}
              text={t('hlPrivacyPolicy')}
              title={t('LinkDescription_GoogleRecaptcha_PrivacyPolicy')}
              ampEvent={events.USER_CLICKED_GOOGLE_RECAPTCHA_PRIVACY_POLICY_LINK}
            />
          )}
          replaceWithSecond={(
            <ExternalLink
              href={t('Link_Google_Terms')}
              text={t('hlTermsConditions')}
              title={t('LinkDescription_GoogleRecaptcha_Terms')}
              ampEvent={events.USER_CLICKED_GOOGLE_RECAPTCHA_TERMS_LINK}
            />
          )}
          replaceKeySecond="xTerms"
          replaceKeyFirst="xPrivacy"
        />
      </p>
    </div>
  );
}
