import { t, translate } from 'react-switch-lang';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import Button from '../Button';
import { events } from '../../utils/Amplitude';
import { setScreenReaderAlert } from '../../utils/Accessibility';
import authStyle from '../../styles/AuthForm.module.scss';

function ResendEmail({ cognitoFunction, authStep }) {
  const [disableResend, setDisableResend] = useState(false);
  const [showResendWarn, setShowResendWarn] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <div className={`${authStyle.flexRow} ${authStyle.flexWrap}`}>
      <Button
        loading={loading}
        text={t('ConfirmEmail_ResendEmail_Btn')}
        ampEvent={events.USER_CLICKED_RESEND_CODE}
        ampEventProps={{ Process: authStep }}
        className={`${disableResend ? authStyle.disableBtn : ''} ${authStyle.smallButton}`}
        onClick={() => {
          setShowResendWarn(false);
          if (!disableResend) {
            setLoading(true);
            cognitoFunction()
              .then((success) => {
                if (!success) return;
                setEmailSent(true);
                setTimeout(() => setEmailSent(false), 3000);
              })
              .finally(() => setLoading(false));
            setDisableResend(true);
            setScreenReaderAlert(t('ScreenReaderAlert_ConfirmEmailResent'));
            setTimeout(() => {
              setDisableResend(false);
              setShowResendWarn(false);
            }, 30000);
          } else {
            setEmailSent(false);
            setShowResendWarn(true);
            setScreenReaderAlert(t('ConfirmEmail_PleaseWait'));
          }
        }}
      />
      {emailSent && (
        <div className={authStyle.flexRow} id="EmailSentAlert">
          <FontAwesomeIcon
            icon={faCheckCircle}
            className={authStyle.textSuccess}
          />
          <p className={authStyle.resendAlertText}>{t('ConfirmEmail_EmailSent')}</p>
        </div>
      )}
      {showResendWarn && (
        <div className={authStyle.flexRow} id="EmailSentAlert">
          <FontAwesomeIcon
            icon={faExclamationCircle}
            className={authStyle.textDanger}
          />
          <p className={`${authStyle.resendAlertText} ${authStyle.emailResendWaitTxt}`}>{t('ConfirmEmail_PleaseWait')}</p>
        </div>
      )}
    </div>
  );
}

export default translate(ResendEmail);
