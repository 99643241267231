import { t } from 'react-switch-lang';
import { useState, useEffect } from 'react';

import MainLayout from '../../components/MainLayout';
import LoginForm from '../../components/auth/LoginForm';
import ConfirmEmailForm from '../../components/auth/ConfirmEmailForm';
import { checkSession } from '../../components/Router';
import { events, logAmpEvent } from '../../utils/Amplitude';
import { AUTH_STEP } from '../../utils/Constants';

function Login() {
  const [step, setStep] = useState(AUTH_STEP.LOGIN);

  useEffect(() => {
    logAmpEvent(events.USER_VIEWED_LOGIN_PAGE);
  }, []);

  return (
    <MainLayout authPage showLanguageSwitcher title={t(`PageTitle_${step.replace(/\s+/g, '')}`)}>
      {step === AUTH_STEP.LOGIN ? (
        <LoginForm setStep={setStep} />
      ) : (
        <ConfirmEmailForm setStep={setStep} process={AUTH_STEP.LOGIN} />
      )}
    </MainLayout>
  );
}

export default checkSession(Login, true, true);
export { getStaticPaths, getStaticProps } from '../../utils/DynamicRoutes';
